import NotFound from "$components/NotFound";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Rc from "./components/Rc";
import Tc from "./components/Tc";
import "./i18n";
import InnerApp from "./InnerApp";
import { queryClient } from "./utils/queryClient";

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/rc" component={Rc} />
          <Route exact path="/tc" component={Tc} />
          <Route exact path="/:id" component={InnerApp} />
          <Route
            exact
            path="/"
            render={() => {
              window.location.assign(process.env.SITE_URL!);
              return null;
            }}
          />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
