Start Exam: 試験を開始
Do you really finish this exam?: 本当に試験を終了しますか？
Finish Exam: 試験を終了
Finish: 終了
Not now: あとで
Time remaining: 残り時間
Show time: 時間を表示
Hide time: 時間を隠す
Please wait a proctor: 試験官をお待ちください
Please connect remote camera: スマホカメラを連携してください
Please connect camera: カメラを接続してください
Please connect microphone: マイクを接続してください
Please add screen share: 共有画面を追加してください
Request to start exam: 試験開始をリクエスト
Call Proctor: 試験官とチャット
Write a message: メッセージを入力
Send: 送信
Sending: 送信中
Close: 閉じる
Notepad: メモ帳
Take a note: メモを入力
Video Session: ビデオセッション
Remote Camera: スマホカメラ
Show QR Code: QRコードを表示
Camera: カメラ
Microphone: マイク
Add Shared Screen: 共有画面を追加
This exam is over: この試験は終了しました
Not Found: 試験が見つかりません
NotFoundHint: 別のアカウントで受験された場合は下部のリンクからログアウトしてください。
Logout: ログアウト
achievements page: 試験結果ページ
finishedPreMessageWithSub: ""
finishedPostMessageWithSub: から成績をご確認いただけます。試験によっては開示は後日になります。
finishedMessageWithoutSub: 受験者ページの「試験結果」から成績をご確認いただけます。試験によっては開示は後日になります。
finishedNoteWithoutSub: ※ 受験者ページは受験団体によっては提供していない場合があります。
instantDisclosureMessage: このページでも結果を表示できます
instantDisclosureNote: 詳細は試験結果ページをご覧ください
instantDisclosureButton: 成績を表示
hideButton: 成績を非表示
reInstantDisclosureMessage: 結果を再度表示できます
reInstantDisclosureButton: 成績を再表示
Result: 合否
Success: 合格
Fail: 不合格
Score: 得点
Result is not available: 結果が見つかりませんでした
Fail to get result: 結果を取得できませんでした
Operation not allowed: 許可されていない操作です
This exam is aborted: この試験は中止しました
Fail to access devices: デバイスのアクセスに失敗しました
Fail to subscribe video: ビデオの受信に失敗しました
Fail to connect to video session: ビデオセッションの接続に失敗しました
Fail to preview camera: カメラのプレビューに失敗しました
Fail to preview screen: 画面のプレビューに失敗しました
Fail to publish screen: 画面の送信に失敗しました
Fail to publish video: ビデオの送信に失敗しました
Exam can be taken by only one browser: 複数のブラウザからは受験できません
Input your test ID: 受験IDを入力してください
Input your PIN: PINを入力してください
Go to test page: 受験ページに行く
Test ID or PIN is not correct: 受験IDかPINが正しくありません
Back to test ID input: 受験IDの入力に戻る
Copy is prohibited: コピーはできません
Cut is prohibited: 切り取りはできません
Paste is prohibited: ペーストはできません
Remote camera was connected: スマホカメラが接続されました
Remote camera was disconnected: スマホカメラが切断されました
qrCodeTitle: スマホカメラ連携
qrCodeDescription: スマートフォンでQRコードを読み取って、専用ページを開いてください
Connecting: 接続試行中
Streaming: ストリーミング中
Disconnected: 切断されています
Hide Preview: プレビューを非表示
Show Preview: プレビューを表示
DisconnectedMessage: スマホカメラを連携するには、ページを再読み込みしてください
instruction:
  faq:
    text: よくある質問
    url: https://excert-faq.notion.site
  remoteCamera:
    head: スマホカメラを連携してください
  camera:
    head: カメラを接続してください
    desc:
      normal: カメラの使用の許可を求められた場合は、許可してください。
      failed: カメラに接続できませんか？
  microphone:
    head: マイクを接続してください
    desc:
      normal: マイクの使用の許可を求められた場合は、許可してください。
      failed: マイクに接続できませんか？
  screenShare:
    head: 共有画面を追加してください
    desc:
      connecting:
        pre: プレビュー画像をクリックして選択し、
        share: 共有
        post: を押してください。
      failed: 画面を共有できませんか？
  proctor:
    head: 試験官をお待ちください
