import NotFound from "$components/NotFound";
import TrialAborted from "$components/TrialAborted";
import TrialEnded from "$components/TrialEnded";
import { TrialInfo } from "$types/TrialInfo";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import AppLayout from "./AppLayout";
import "./utils/global";

interface AppContext {
  trialId: string;
  trialInfo: TrialInfo;
  refetchTrialInfo: () => Promise<void>;
  trialSignature?: string;
  setTrialSignature: (signature: string) => void;
}

const AppContext = createContext<AppContext | undefined>(undefined);

export const useApp = (): AppContext => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw Error("useApp cannot be used outside AppContext.Provider");
  }
  return appContext;
};

const InnerApp = () => {
  const {
    params: { id: trialId },
  } = useRouteMatch<{ id: string }>();

  const [trialSignature, setTrialSignature] = useState(
    () => localStorage.getItem(`trialSignature:${trialId}`) ?? undefined
  );

  const {
    data: trialInfo,
    error: trialInfoError,
    refetch: refetchTrialInfo,
  } = useQuery<TrialInfo>([`/api/trial/${trialId}`], {
    onSuccess: (data) => {
      if (data.signature != null) {
        setTrialSignature(data.signature);
      }
    },
  });

  useEffect(() => {
    if (trialSignature == null) {
      return;
    }

    localStorage.setItem(`trialSignature:${trialId}`, trialSignature);
    Cookies.set("trialSignature", trialSignature);

    // when startTrial is called
    if (trialInfo != null && trialInfo.startedAt == null) {
      refetchTrialInfo();
    }
  }, [trialSignature]);

  return trialInfoError ? (
    <NotFound />
  ) : trialInfo == null ? (
    <></>
  ) : !trialInfo.confirmed ? (
    <NotFound />
  ) : trialInfo.aborted ? (
    <TrialAborted />
  ) : (
    <AppContext.Provider
      value={{
        trialId,
        trialInfo,
        refetchTrialInfo: async () => {
          await refetchTrialInfo();
        },
        trialSignature,
        setTrialSignature,
      }}
    >
      {trialInfo.remainingMills != null && trialInfo.remainingMills <= 0 ? (
        <TrialEnded />
      ) : (
        <AppLayout />
      )}
    </AppContext.Provider>
  );
};

export default InnerApp;
