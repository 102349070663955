import { Button, Modal } from "antd";
import { ButtonProps } from "antd/lib/button/button";
import QRCode from "qrcode";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props extends ButtonProps {
  rcToken?: string;
  children?: React.ReactNode;
}

const RcButton = ({ rcToken, children, ...props }: Props) => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        {...props}
        onClick={({ currentTarget }) => {
          currentTarget.blur();
          showModal();
        }}
      >
        {children}
      </Button>
      <Modal
        title={t("qrCodeTitle")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        transitionName={""}
        maskTransitionName={""}
        width={520}
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <div>{t("qrCodeDescription")}</div>
        <canvas
          ref={(element) => {
            if (element == null) {
              return;
            }
            QRCode.toCanvas(
              element,
              `${process.env.EXAM_URL!}/rc?t=${rcToken ?? ""}`,
              { width: 256 }
            );
          }}
        />
      </Modal>
    </>
  );
};

export default RcButton;
