import { CloseOutlined } from "@ant-design/icons";
import { Input, message, Tooltip } from "antd";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Rnd } from "react-rnd";

export interface NotepadActions {
  setNotepadVisible: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  trialId: string;
  actionsRef?: MutableRefObject<NotepadActions | undefined>;
}

const Notepad = ({ trialId, actionsRef }: Props) => {
  const { t } = useTranslation();

  const [isNotepadVisible, setNotepadVisible] = useState<boolean>(false);

  const [text, setText] = useState<string>(
    localStorage.getItem(`notepad:${trialId}`) ?? ""
  );
  useEffect(() => {
    localStorage.setItem(`notepad:${trialId}`, text);
  }, [text]);

  useEffect(() => {
    if (actionsRef == null) {
      return;
    }
    actionsRef.current = {
      setNotepadVisible,
    };
  }, [actionsRef]);

  const [isDragging, setIsDragging] = useState(false);
  const initialWidth = 320;

  return (
    <div className="notepad-container">
      <Rnd
        className={`notepad-window-container${isDragging ? " dragging" : ""}${
          isNotepadVisible ? "" : " hidden"
        }`}
        dragHandleClassName="handle"
        bounds="parent"
        enableResizing={false}
        default={{
          x: window.innerWidth - initialWidth - 12,
          y: 12,
          width: initialWidth,
          height: "auto",
        }}
        onResizeStart={() => {
          setIsDragging(true);
        }}
        onResizeStop={() => {
          setIsDragging(false);
        }}
        onDragStart={() => {
          setIsDragging(true);
        }}
        onDragStop={() => {
          setIsDragging(false);
        }}
      >
        <div className="notepad-window">
          <div className="header-bar handle">
            <span>{t("Notepad")}</span>
            <Tooltip title={t("Close")}>
              <CloseOutlined
                className="close"
                style={{ display: "inline", cursor: "pointer" }}
                onClick={() => {
                  setNotepadVisible(false);
                }}
                onTouchEnd={() => {
                  setNotepadVisible(false);
                }}
              />
            </Tooltip>
          </div>
          <div className="content">
            <Input.TextArea
              className="text"
              autoSize={{ minRows: 10, maxRows: 10 }}
              placeholder={t("Take a note")}
              value={text}
              onChange={({ target: { value } }) => {
                setText(value);
              }}
              onCopy={(event) => {
                event.preventDefault();
                message.warn(t("Copy is prohibited"));
              }}
              onCut={(event) => {
                event.preventDefault();
                message.warn(t("Cut is prohibited"));
              }}
              onPaste={(event) => {
                event.preventDefault();
                message.warn(t("Paste is prohibited"));
              }}
            />
          </div>
        </div>
      </Rnd>
    </div>
  );
};

export default Notepad;
