import { RcInfo } from "$scripts/types/RcInfo";
import OT from "@opentok/client";
import { useQuery } from "@tanstack/react-query";
import { Button, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

const Rc = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { t: rcToken } = useMemo(
    () =>
      Object.fromEntries(new URLSearchParams(search).entries()) as {
        t?: string;
      },
    [search]
  );

  const {
    data: rcInfo,
    error: rcInfoError,
    refetch: refetchRcInfo,
  } = useQuery<RcInfo>([`/api/rc/${rcToken}`]);

  useEffect(() => {
    const remainingMills = rcInfo?.remainingMills;
    if (!remainingMills) {
      return;
    }
    const timeoutId = window.setTimeout(() => {
      refetchRcInfo();
    }, remainingMills);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [rcInfo?.remainingMills]);

  if (rcInfoError != null) {
    return (
      <div className="outer-rc unavailable">
        <h1 style={{ color: "OrangeRed" }}>{t("Fail to get info")}</h1>
      </div>
    );
  }

  if (rcInfo == null) {
    return <div className="outer-rc" />;
  }

  if (!rcInfo.available || rcInfo.ot == null) {
    return (
      <div className="outer-rc unavailable">
        <h1>
          {rcInfo.remainingMills != null
            ? t("This exam is over")
            : t("Remote Camera is not available")}
        </h1>
      </div>
    );
  }

  return (
    <div className="outer-rc">
      <InnerRc
        trialId={rcInfo.trialId}
        enableRemoteCamera={rcInfo.enableRemoteCamera}
        enableRemoteMicrophone={rcInfo.enableRemoteMicrophone}
        sessionId={rcInfo.ot.sessionId}
        token={rcInfo.ot.token}
        onSignal={(event) => {
          if (
            ["trialUpdatedByStaff", "isOnTrialChanged"].includes(event.type) &&
            rcInfo.available
          ) {
            refetchRcInfo();
          }
        }}
      />
    </div>
  );
};

interface InnerRcProps {
  trialId: string;
  enableRemoteCamera: boolean;
  enableRemoteMicrophone: boolean;
  sessionId: string;
  token: string;
  onSignal?: (event: { type: string; data?: any }) => void;
}

const InnerRc = ({
  trialId,
  enableRemoteCamera,
  enableRemoteMicrophone,
  sessionId,
  token,
  onSignal,
}: InnerRcProps) => {
  const { t } = useTranslation();

  const [session, setSession] = useState<OT.Session>();

  useEffect(() => {
    const _session = OT.initSession(process.env.OPENTOK_APIKEY!, sessionId);
    setSession(_session);
    return () => {
      _session.disconnect();
    };
  }, [sessionId]);

  const [isConnected, setIsConnected] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);

  useEffect(() => {
    if (isConnected) {
      setIsDisconnected(false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (session == null) {
      return;
    }
    session.on("sessionConnected", () => {
      setIsConnected(true);
    });
    session.on("sessionDisconnected", ({ reason }) => {
      setIsConnected(false);
      if (reason === "networkDisconnected") {
        setIsDisconnected(true);
      }
    });
    session.on("sessionReconnected", () => {
      setIsConnected(true);
    });
    session.on("signal", (event) => {
      onSignal?.({
        type: event.type.split(":")[1],
        ...(event.data && { data: JSON.parse(event.data) }),
      });
    });
    session.connect(token, (error) => {
      if (error) {
        message.error(t("Fail to connect to video session"));
        return;
      }
      if (session.capabilities.publish !== 1) {
        message.error(t("audio-video stream cannot be published."));
        return;
      }
    });
  }, [session]);

  const [publisher, setPublisher] = useState<OT.Publisher>();

  useEffect(() => {
    if (session == null || !isConnected) {
      return;
    }
    const _publisher = OT.initPublisher(
      "remote-camera-preview",
      {
        insertMode: "append",
        showControls: false,
        publishVideo: enableRemoteCamera,
        publishAudio: enableRemoteMicrophone,
        videoSource: enableRemoteCamera,
        audioSource: enableRemoteMicrophone,
        width: "100%",
        height: "100%",
        name: JSON.stringify({ kind: "user", remote: true, trialId }),
        facingMode: "user",
        resolution: "1280x720",
        frameRate: 7,
        style:
          !enableRemoteCamera && enableRemoteMicrophone
            ? { backgroundImageURI: "/static/audio.svg" }
            : {},
      },
      (error) => {
        if (error) {
          message.error(t("Fail to preview camera"));
          return;
        }
        session.publish(_publisher, (err) => {
          if (err) {
            message.error(t("Fail to publish camera"));
            return;
          }
        });
      }
    );
    _publisher.on("accessDenied", () => {
      message.error(t("Fail to access devices"));
    });
    _publisher.on("streamCreated", (event) => {
      setPublisher(_publisher);
    });
    _publisher.on("streamDestroyed", (event) => {
      setPublisher(undefined);
    });
  }, [session, isConnected]);

  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    if (publisher == null) {
      setHidden(false);
    }
  }, [publisher]);

  return (
    <>
      <div className="inner-rc">
        <div id="remote-camera-preview" className="video-preview" />
      </div>
      <div className={`overlay ${hidden ? "hide" : ""}`}>
        <div className="status">
          {publisher != null ? (
            <>
              <span className="indicator streaming">●</span>
              <span>{t("Streaming")}</span>
            </>
          ) : !isDisconnected ? (
            <>
              <span className="indicator connecting">●</span>
              <span>{t("Connecting")}</span>
            </>
          ) : (
            <>
              <span className="indicator disconnected">●</span>
              <span>{t("Disconnected")}</span>
            </>
          )}
        </div>
        {publisher == null || !enableRemoteCamera ? (
          <></>
        ) : (
          <button
            className="toggle-preview"
            onClick={() => {
              setHidden((prev) => !prev);
            }}
          >
            {hidden ? t("Show Preview") : t("Hide Preview")}
          </button>
        )}
        <div className="text">
          {!isDisconnected ? <></> : <span>{t("DisconnectedMessage")}</span>}
        </div>
      </div>
    </>
  );
};

export default Rc;
