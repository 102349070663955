import React, { ReactElement } from "react";

const regex =
  /(?<=^|[\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}\p{P}\s])https?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]/giu;

interface Props {
  text: string;
}

const TextWithLinks = ({ text }: Props) => {
  const parts: ReactElement[] = [];
  let lastIndex = 0;
  for (const match of text.matchAll(regex)) {
    if (match.index == null) {
      return <span>{text}</span>;
    }
    if (match.index > lastIndex) {
      parts.push(
        <span key={lastIndex}>{text.substring(lastIndex, match.index)}</span>
      );
    }
    parts.push(
      <a
        key={match.index}
        href={match[0]}
        target="_blank"
        rel="noopener noreferrer"
      >
        {match[0]}
      </a>
    );
    lastIndex = match.index + match[0].length;
  }
  if (lastIndex < text.length) {
    parts.push(<span key={-1}>{text.substring(lastIndex)}</span>);
  }
  return <>{parts}</>;
};

export default TextWithLinks;
