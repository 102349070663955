NotFoundHint: "Please click logout if an exam was taken by a different account on your computer."
achievements page: achievements page
finishedPreMessageWithSub: "The result will be available at "
finishedPostMessageWithSub: " after marking."
finishedMessageWithoutSub: "The result will be at user page if available after marking."
finishedNoteWithoutSub: ""
instantDisclosureMessage: The result is also available at this page.
instantDisclosureNote: Please visit achievements page for details.
instantDisclosureButton: Show Result
hideButton: Hide Result
reInstantDisclosureMessage: Achievements can be redisplayed.
reInstantDisclosureButton: Redisplay Result
qrCodeTitle: Remote Camera Connection
qrCodeDescription: Scan the QR code on your phone to open the connection page.
DisconnectedMessage: Please reload this page to connect this camera
instruction:
  faq:
    text: FAQ
    url: https://excert-faq.notion.site
  remoteCamera:
    head: Please connect remote camera
  camera:
    head: Please connect camera
    desc:
      normal: If you are asked for permission to use your camera, please allow it.
      failed: Can't connect to the camera?
  microphone:
    head: Please connect microphone
    desc:
      normal: If you are asked for permission to use your microphone, please allow it.
      failed: Can't connect to the microphone?
  screenShare:
    head: Please add screen share
    desc:
      connecting:
        pre: Click on the preview image to select it and press
        share: Share
        post: .
      failed: Can't share your screen?
  proctor:
    head: Please wait a proctor
