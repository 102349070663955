import { Typography } from "antd";
import { detect } from "detect-browser";
import React from "react";
import { useTranslation } from "react-i18next";

const { Text } = Typography;
const browser = detect();

interface Props {
  rcToken: string | undefined;
  enableCamera: boolean;
  enableMicrophone: boolean;
  enableScreenShare: boolean;
  subscribingStreams: { [streamId: string]: OT.Stream };
  streamingPublishers: { [streamId: string]: OT.Publisher };
  isCameraPreviewFailed: boolean;
  isGettingDisplayMedia: boolean;
  isScreenPreviewFailed: boolean;
}

const Instruction = ({
  rcToken,
  enableCamera,
  enableMicrophone,
  enableScreenShare,
  subscribingStreams,
  streamingPublishers,
  isCameraPreviewFailed,
  isGettingDisplayMedia,
  isScreenPreviewFailed,
}: Props) => {
  return (
    <div className="request-instruction">
      {rcToken != null &&
      !Object.values(subscribingStreams).some(({ name }) => {
        const { kind, remote } = JSON.parse(name);
        return kind === "user" && remote;
      }) ? (
        <RemoteCamera />
      ) : enableCamera &&
        !Object.values(streamingPublishers).some(
          ({ stream }) => stream?.videoType !== "custom" && stream?.hasVideo
        ) ? (
        <Camera isCameraPreviewFailed={isCameraPreviewFailed} />
      ) : enableMicrophone &&
        !Object.values(streamingPublishers).some(
          ({ stream }) => stream?.videoType !== "custom" && stream?.hasAudio
        ) ? (
        <Microphone isCameraPreviewFailed={isCameraPreviewFailed} />
      ) : enableScreenShare &&
        !Object.values(streamingPublishers).some(
          ({ stream }) => stream?.videoType === "custom"
        ) ? (
        <ScreenShare
          isGettingDisplayMedia={isGettingDisplayMedia}
          isVideoPreviewFailed={isScreenPreviewFailed}
        />
      ) : (
        <Proctor />
      )}
    </div>
  );
};

const RemoteCamera = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="head">{t("instruction.remoteCamera.head")}</div>
    </>
  );
};

const Camera = ({
  isCameraPreviewFailed,
}: {
  isCameraPreviewFailed: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="head">{t("instruction.camera.head")}</div>
      {!isCameraPreviewFailed ? (
        <div className="desc">
          <span>{t("instruction.camera.desc.normal")}</span>
        </div>
      ) : (
        <div className="desc">
          <span>
            {t("instruction.camera.desc.failed")}
            <a
              href={t("instruction.faq.url")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("instruction.faq.text")}
            </a>
          </span>
        </div>
      )}
    </>
  );
};

const Microphone = ({
  isCameraPreviewFailed,
}: {
  isCameraPreviewFailed: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="head">{t("instruction.microphone.head")}</div>
      {!isCameraPreviewFailed ? (
        <div className="desc">
          <span>{t("instruction.microphone.desc.normal")}</span>
        </div>
      ) : (
        <div className="desc">
          <span>
            {t("instruction.microphone.desc.failed")}
            <a
              href={t("instruction.faq.url")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("instruction.faq.text")}
            </a>
          </span>
        </div>
      )}
    </>
  );
};

const ScreenShare = ({
  isGettingDisplayMedia,
  isVideoPreviewFailed,
}: {
  isGettingDisplayMedia: boolean;
  isVideoPreviewFailed: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="head">{t("instruction.screenShare.head")}</div>
      {isGettingDisplayMedia &&
      (browser?.name === "chrome" || browser?.name === "edge-chromium") ? (
        <div className="desc">
          <span>
            {t("instruction.screenShare.desc.connecting.pre")}
            <Text code style={{ whiteSpace: "nowrap" }}>
              {t("instruction.screenShare.desc.connecting.share")}
            </Text>
            {t("instruction.screenShare.desc.connecting.post")}
          </span>
        </div>
      ) : isVideoPreviewFailed ? (
        <div className="desc">
          <span>
            {t("instruction.screenShare.desc.failed")}
            <a
              href={t("instruction.faq.url")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("instruction.faq.text")}
            </a>
          </span>
        </div>
      ) : null}
    </>
  );
};

const Proctor = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="head">{t("instruction.proctor.head")}</div>
    </>
  );
};

export default Instruction;
