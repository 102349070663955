import { ChatMessage } from "$types/ChatMessage";
import { db } from "$utils/firebase";
import { CloseOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Input, Tooltip } from "antd";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Rnd } from "react-rnd";
import TextWithLinks from "./TextWithLinks";

export interface ChatActions {
  setChatVisible: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  trialId: string;
  activated: boolean;
  actionsRef?: MutableRefObject<ChatActions | undefined>;
  onMessage?: (messages: ChatMessage[], isNew: boolean) => void;
  onVisibilityChanged?: (visible: boolean) => void;
}

const Chat = ({
  trialId,
  activated,
  actionsRef,
  onMessage,
  onVisibilityChanged,
}: Props) => {
  const { t } = useTranslation();

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const messagesRef = useRef<HTMLDivElement>(null);

  const [isIncoming, setIsIncoming] = useState(false);

  const [isChatVisible, setChatVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!activated) {
      return;
    }
    const unsubscribe = onSnapshot(
      query(
        collection(db, "trials", trialId, "messages"),
        orderBy("createdAt", "asc")
      ),
      (snapshot) => {
        const _messages: ChatMessage[] = [];
        snapshot.forEach((result) => {
          const raw = result.data();
          _messages.push({
            id: result.id,
            ...raw,
            createdAt: raw.createdAt
              ? new Date(raw.createdAt.seconds * 1000)
              : undefined,
          } as ChatMessage);
        });
        setMessages(_messages);
      }
    );
    return unsubscribe;
  }, [trialId, activated]);

  useEffect(() => {
    const element = messagesRef.current;
    if (element != null) {
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }

    if (messages.length === 0) {
      return;
    }

    const key = `lastChatArraival:${trialId}`;
    const lastStaffMessage = messages
      .filter(({ isUser, createdAt }) => !isUser && createdAt != null)
      .slice(-1)[0] as ChatMessage | undefined;
    const lastStaffMessageCreatedAt = lastStaffMessage?.createdAt?.getTime();
    const isNew =
      lastStaffMessageCreatedAt != null &&
      String(lastStaffMessageCreatedAt) !== localStorage.getItem(key);

    onMessage?.(messages, isNew);

    if (isNew) {
      localStorage.setItem(key, String(lastStaffMessageCreatedAt));
      setIsIncoming(true);
      const timeoutId = window.setTimeout(() => {
        setIsIncoming(false);
      }, 2000);

      return () => {
        window.clearTimeout(timeoutId);
      };
    }
  }, [messages]);

  useEffect(() => {
    onVisibilityChanged?.(isChatVisible);
  }, [isChatVisible]);

  useEffect(() => {
    if (actionsRef == null) {
      return;
    }
    actionsRef.current = {
      setChatVisible,
    };
  }, [actionsRef]);

  const [text, setText] = useState("");

  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const sendMessage = () => {
    if (text.length === 0) {
      return;
    }

    (async () => {
      setIsSendingMessage(true);
      addDoc(collection(db, "trials", trialId, "messages"), {
        text,
        isUser: true,
        createdAt: serverTimestamp(),
      });
      setText("");
      setIsSendingMessage(false);
    })();
  };

  const [isDragging, setIsDragging] = useState(false);
  const [size, setSize] = useState({ width: 370, height: 550 });
  const minSize = { width: 200, height: 320 };

  return (
    <div className="chat-container">
      <Rnd
        className={`chat-window-container${isDragging ? " dragging" : ""}${
          isChatVisible ? "" : " hidden"
        }`}
        dragHandleClassName="handle"
        bounds="parent"
        default={{
          x: window.innerWidth - size.width - 12,
          y: 12,
          ...size,
        }}
        minWidth={minSize.width}
        minHeight={minSize.height}
        size={size}
        onResize={(e, direction, ref) => {
          setSize({
            width: ref.offsetWidth,
            height: ref.offsetHeight,
          });
        }}
        onResizeStart={() => {
          setIsDragging(true);
        }}
        onResizeStop={() => {
          setIsDragging(false);
        }}
        onDragStart={() => {
          setIsDragging(true);
        }}
        onDragStop={() => {
          setIsDragging(false);
        }}
      >
        <div className="chat-window">
          <div className={`header-bar handle ${isIncoming ? "incoming" : ""}`}>
            <span>{t("Call Proctor")}</span>
            <Tooltip title={t("Close")}>
              <CloseOutlined
                className="close"
                style={{ display: "inline", cursor: "pointer" }}
                onClick={() => {
                  setChatVisible(false);
                }}
                onTouchEnd={() => {
                  setChatVisible(false);
                }}
              />
            </Tooltip>
          </div>
          <div className="messages" ref={messagesRef}>
            {messages.map((message) => (
              <div
                className={`message ${message.isUser ? "me" : "other"}`}
                key={message.id}
              >
                <div className="content">
                  <div className="text">
                    <TextWithLinks text={message.text} />
                  </div>
                </div>
                <div className="at">
                  {!message.createdAt
                    ? t("Sending")
                    : message.createdAt.toLocaleString()}
                </div>
              </div>
            ))}
          </div>
          <div className="footer-bar">
            <Input.TextArea
              className="text"
              autoSize={{ minRows: 1 }}
              placeholder={t("Write a message")}
              value={text}
              onChange={({ target: { value } }) => {
                const prevLineCount = text.split("\n").length;
                const newLineCount = value.split("\n").length;
                const { lineHeight } = window.getComputedStyle(
                  document.querySelector(".chat-window .footer-bar > .text")!
                );
                setSize((prev) => ({
                  ...prev,
                  height: Math.max(
                    prev.height +
                      (newLineCount - prevLineCount) * parseFloat(lineHeight),
                    minSize.height
                  ),
                }));
                setText(value);
              }}
            />
            <Tooltip title={t("Send")}>
              <Button
                className="send"
                type="text"
                shape="circle"
                disabled={!activated || isSendingMessage || text.length === 0}
                icon={<SendOutlined />}
                onClick={() => {
                  sendMessage();
                }}
              />
            </Tooltip>
          </div>
        </div>
      </Rnd>
    </div>
  );
};

export default Chat;
